const easing = Expo.easeInOut

TweenMax.from(".hero .left .intro_hire", 2, {
    delay: .5,
    y: 80,
    opacity: 0,
    ease: easing
});

TweenMax.from(".hero .left h1", 2, {
    delay: .4,
    y: 70,
    opacity: 0,
    ease: easing
});

TweenMax.from(".hero .left .btn", 2, {
    delay: .2,
    y: 70,
    opacity: 0,
    ease: easing
});

TweenMax.from(".hero .right img", 2, {
    delay: 1.2,
    y: 60,
    opacity: 0,
    ease: easing
});

var heroImg = document.querySelector(".hero .right img");
heroImg.addEventListener("mouseover", onHoverOver);
heroImg.addEventListener("mouseout", onHoverOut);

function onHoverOver(e) {
    TweenMax.to(".hero .right img", 0.6, {
        y: -15
    });
}

function onHoverOut(e) {
    TweenMax.to(".hero .right img", 1.5, {
        y: 0,
        ease: Expo.easeOut
    });
}


var links = document.querySelectorAll('a[href*="#"]');
for (var l = 0; l < links.length; l++) {
    links[l].addEventListener('click', scrollMe, false);
}

function scrollMe(e) {
    e.preventDefault();

    var hash = e.target.hash;

    if (hash) {
        document.querySelector(hash).scrollIntoView({
            behavior: 'smooth'
        });
    }
}